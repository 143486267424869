import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';

import * as _ from 'lodash';

import { Common } from '../../_common';
import { ApiService } from '../../shared/_services/api.service';
import {
  AuthPermissions,
  AuthPermissionsCRUD,
  CtrlNavigationEntryRendererAuthConfig,
  CurrentUser,
  KeycloakInfo,
  KeycloakToken,
  KeycloakTokenDecoded,
  UserWithRoles
} from '../../shared/_models/models';
import { RequestCacheService } from '../../shared/_services/request-cache.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { WindowService } from '../../shared/_services/window.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SharedStoreService } from '../../shared/_services/shared-store.service';
import { PendoService } from '../../shared/_services/pendo.service';
import { DatadogService } from '../../shared/_services/datadog.service';
import { VersionCheckService } from '../../shared/_services/version-check.service';

@Injectable({providedIn: 'root'})
export class AuthenticationService {

  currentUser: Observable<CurrentUser>;
  isAuthenticated: Observable<boolean>;
  private currentUserSubject: BehaviorSubject<CurrentUser>;
  private isAuthenticatedSubject: BehaviorSubject<boolean>;
  private isReAuthInProgress = false;
  unsubscribe$ = new Subject<void>();

  private jwtHelperService = new JwtHelperService();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private common: Common,
    private api: ApiService,
    private sharedStoreService: SharedStoreService,
    private requestCacheService: RequestCacheService,
    private notification: NzNotificationService,
    private windowService: WindowService,
    private datadogService: DatadogService,
    private pendoService: PendoService,
    private vcs: VersionCheckService,
  ) {
    let currentUserFromLocalStorage: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUserFromLocalStorage && !currentUserFromLocalStorage.roles.every(element => typeof element === 'string')) {
      currentUserFromLocalStorage = null;
      localStorage.removeItem('currentUser');
      this.router.navigate(['/auth/login']);
    }
    this.currentUserSubject = new BehaviorSubject<CurrentUser>(currentUserFromLocalStorage);
    this.currentUser = this.currentUserSubject.asObservable();
    this.isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
    this.isAuthenticated = this.isAuthenticatedSubject.asObservable();
    // set Java auth to true if javaUser exists in local storage
    this.currentUser.pipe(takeUntil(this.unsubscribe$)).subscribe((currentUser: CurrentUser) => {
      this.isAuthenticatedSubject.next(currentUser ? true : false);
    });
    if (this.currentUserSubject.value) {
      this.isAuthenticatedSubject.next(true);
    }

    // Stuff to do whenever Auth state changes
    this.isAuthenticated.pipe(
      debounceTime(100),
      distinctUntilChanged(),
    ).subscribe(res => {
      if (res) {
        // user is logged in (will apply also when refreshing the page)
        this.sharedStoreService.getGlobalAppAssets(this.currentUserValue);
      }
      this.datadogService.onDatadogAction('setUser', this.currentUserValue);
      this.pendoService.onPendoAction('setOptions', this.currentUserValue);
    });
  }

  public get currentUserValue(): CurrentUser {
    return this.currentUserSubject.value;
  }

  // Check if user has all permissions in list (can also use * for multiple permissions in group)
  public hasAccess(permissionsToCheck: string | Array<string>) {
    if (!Array.isArray(permissionsToCheck)) {
      permissionsToCheck = [permissionsToCheck];
    }
    let permissionsCheckLeft = permissionsToCheck.length;
    const user = this.currentUserValue;
    const userPermissionListCount = user && user.permissions ? user.permissions.length : 0;
    for (let i = permissionsToCheck.length - 1; i >= 0; i--) {
      for (let j = userPermissionListCount - 1; j >= 0; j--) {
        if (user && this.common.match(user.permissions[j], permissionsToCheck[i])) {
          permissionsCheckLeft--;
          break;
        }
      }
    }
    return !permissionsCheckLeft;
  }

  // Check if user has any of the permissions in list (can also use * for multiple permissions in group)
  public hasAnyAccess(permissionsToCheck: string | Array<string>) {
    if (!Array.isArray(permissionsToCheck)) {
      permissionsToCheck = [permissionsToCheck];
    }
    const user = this.currentUserValue;
    const userPermissionListCount = user.permissions ? user.permissions.length : 0;
    for (let i = permissionsToCheck.length - 1; i >= 0; i--) {
      for (let j = userPermissionListCount - 1; j >= 0; j--) {
        if (user && this.common.match(user.permissions[j], permissionsToCheck[i])) {
          return true;
        }
      }
    }
  }

  public getPermissionsList(requiredPermissions: AuthPermissionsCRUD): AuthPermissions {
    return {
      canRead: requiredPermissions.read && this.isInPermissions(requiredPermissions.read),
      canCreate: requiredPermissions.create && this.isInPermissions(requiredPermissions.create),
      canUpdate: requiredPermissions.update && this.isInPermissions(requiredPermissions.update),
      canDelete: requiredPermissions.delete && this.isInPermissions(requiredPermissions.delete),
    };
  }

  public hasRole(roleName: string): boolean {
    return this.currentUserValue?.roles?.filter(role => role.toLowerCase() === roleName.toLowerCase()).length ? true : false;
  }

  isInPermissions(lookForPermission: string): boolean {
    const isInPermissions = _.findIndex(this.currentUserValue.permissions,  lookForPermission) > -1;
    let isInPermissionsList = false;
    if (this.currentUserValue.permissions) {
      isInPermissionsList = this.currentUserValue.permissions.includes(lookForPermission);
    }
    return isInPermissions || isInPermissionsList;
  }

  isPassAllNavigationEntryAuthConfigCriteria(config: CtrlNavigationEntryRendererAuthConfig): boolean {
    let isPermissionsPass = true;
    let isRolesPass = true;
    if (config.userPermissions) {
      if (config.userPermissions.operand === 'OR') {
        isPermissionsPass = this.hasAnyAccess(config.userPermissions.permissions);
      } else if (config.userPermissions.operand === 'AND') {
        isPermissionsPass = this.hasAccess(config.userPermissions.permissions);
      }
    }
    if (config.userRoles) {
      Object.keys(config.userRoles).forEach(role => {
        if (this.hasRole(role) !== config.userRoles[role]) {
          isRolesPass = false;
        }
      });
    }
    return isPermissionsPass && isRolesPass;
  }

  public logout(): Observable<void> {
    const currentUser: KeycloakToken = this.currentUserValue;
    if (currentUser) {
      return this.api.javaLogout(currentUser.refresh_token).pipe(
        tap(() => {
          this.resetOnLogout();
          this.router.navigate(['/auth/login']);
        })
      );
    }
  }

  public doLogout(withNotification = false) {
    // java logout
    const currentUser: KeycloakToken = this.currentUserValue;
    if (currentUser) {
      this.api.javaLogout(currentUser.refresh_token)
        .subscribe(() => {
        }, () => {
          // this.notification.warning('Logout Error', 'Server logout failed');
        });
    }
    this.resetOnLogout();
    if (withNotification) {
      this.notification.warning('Reauthentication required', 'Please log in');
    }
    this.router.navigate(['/auth/login']);
    setTimeout(() => {
      this.vcs.refreshPageIfNeeded();
    }, 1000);
  }

  getKeycloakToken(email: string, password: string): Observable<KeycloakToken> {
    return this.api.getKeycloakToken(email, password);
  }

  getKeycloakUserInfo(accessToken: string): Observable<KeycloakInfo> {
    return this.api.getKeycloakUserInfo(accessToken);
  }

  login(email: string, password: string, returnUrl: string): Observable<CurrentUser> {
    let keycloakToken: KeycloakToken;
    let tokenDecoded: KeycloakTokenDecoded;
    return this.getKeycloakToken(email, password).pipe(
      switchMap((res: KeycloakToken) => {
        keycloakToken = res;
        tokenDecoded = this.jwtHelperService.decodeToken(keycloakToken.access_token);
        if (tokenDecoded.userType === 'API') {
          throw {loginError: 'API user not allowed'};
        }
        return this.getKeycloakUserInfo(keycloakToken.access_token);
      }),
      tap((keycloakInfo: KeycloakInfo) => {
        const currentUser: CurrentUser = this.mapKeycloakObjectsToCurrentUser(keycloakToken, tokenDecoded, keycloakInfo);
        this.onUpdateAccessToken(currentUser);
        this.currentUserSubject.next({...this.currentUserValue, ...currentUser});
        this.onLoginSuccess(returnUrl);
      }),
      catchError(err => {
        this.onLoginError(err, email);
        return of(err);
      })
    );
  }

  mapKeycloakObjectsToCurrentUser(token: KeycloakToken, decoded: KeycloakTokenDecoded, info: KeycloakInfo): CurrentUser {
    return {
      ...token,
      id: decoded.userId,
      firstName: info.given_name,
      lastName: info.family_name,
      email: decoded.preferred_username,
      type: decoded.userType,
      userType: decoded.userType,
      publisherId: decoded.publisherId,
      partnerId: decoded.partnerId,
      timezone: info.timezoneId,
      currency: info.currencyId,
      defaultUi: info.defaultUi,
      permissions: decoded.realm_access?.roles || null,
      roles: decoded.userRoles,
    } as unknown as CurrentUser;
  }

  onLoginSuccess(returnUrl: string) {
    if (this.route.snapshot.queryParams['return_to']) {
      this.getZendeskToken().subscribe(tokenRes => {
        const zendeskUrl = `${environment.zendeskUrl}/access/jwt?jwt=${tokenRes.zendeskToken}`;
        this.windowService.openWindow(zendeskUrl, '_self');
      });
    } else {
      this.navigateToHomePage(returnUrl);
    }
  }

  onLoginError(err, email: string): void {
    if (err.status === 403){
      this.router.navigate(['/auth/password/reset'], { queryParams: { email }});
    } else if (err.error.error === 'user_blocked') {
      this.blockingLogin(err.error.error_description);
    } else {
      this.notification.error('Authentication Failed', err?.loginError || 'Login failed');
      this.resetOnLogout();
    }
  }

  blockingLogin(error_description: string): void {
    localStorage.setItem('dateBlockingFinish', error_description);
    this.router.navigate(['/auth/blocked']);
  }

  refreshAuth(refresh_token) {
    return this.api.refreshAuth(refresh_token);
  }

  onUpdateAccessToken(authRes: KeycloakToken) {
    if (authRes.expires_in) {
      authRes.expiresAt = Math.floor((Date.now() / 1000)) + authRes.expires_in;
    }
    let user = this.currentUserValue;
    user = {...user, ...authRes};
    this.currentUserSubject.next(user);
    this.updateCurrentUserInLocalStorage();
  }

  updateMyUser(userValue: UserWithRoles): void {
    if (userValue.user) {
      const updatedUser = userValue.user;
      let user = this.currentUserValue;
      user = {...user, ...updatedUser};
      this.currentUserSubject.next(user);
      this.updateCurrentUserInLocalStorage();
    }
  }

  updateCurrentUserInLocalStorage(): void {
    localStorage.setItem('currentUser', JSON.stringify(this.currentUserValue));
  }

  resetOnLogout(): void {
    this.authClear();
    this.requestCacheService.clearCache();
    this.sharedStoreService.resetSharedStore();
  }

  authClear() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.isAuthenticatedSubject.next(false);
  }

  isCaptchaTokenValid(token: string) {
    return this.api.captchaValidate(token);
  }

  getZendeskToken(): Observable<{zendeskToken: string}> {
    return this.api.getZendeskToken();
  }

  private navigateToHomePage(returnUrl: string = null): void {
    if (returnUrl) {
      this.router.navigate([returnUrl]);
    } else if (this.currentUserValue.type === 'INTERNAL') {
      if (this.currentUserValue?.roles.some(role => role === 'Exchange Business Team')) {
        this.router.navigate(['/supply/exchange-deals']);
        return;
      }
      this.router.navigate(['/supply']);
    } else if (this.currentUserValue.type === 'EXTERNAL') {
      const hasPublisherDealShowPermission = this.currentUserValue?.permissions.includes('publisher.deal.show');
      if (hasPublisherDealShowPermission && this.currentUserValue?.defaultUi === 'SPEARAD') {
        localStorage.setItem('currentContext', JSON.stringify('spearad'));
        this.router.navigate(['/spearad/dashboard']);
      } else if (this.currentUserValue.partnerId) {
        this.router.navigate(['/partner']);
      } else {
        this.router.navigate(['/portal']);
      }
    }
  }


}
