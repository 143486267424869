<nz-spin [nzSpinning]="isPlacementLoading" nzSize="large">
  <div class="card-wrapper">
    <nz-card>

      <div *ngIf="portalType === 'supply'"
        class="margin-bottom-1 cursor-pointer top-left-back-button font-icon-color-black"
        id="placement-back-button"
        (click)="onBackToTableClick()">
        <fa-icon icon="arrow-left" class="fa-md cursor-pointer"></fa-icon>
        Back
      </div>

      <nz-alert nzType="info" *ngIf="['CREATE', 'DUPLICATE'].includes(editMode) && portalType === 'portal'" nzShowIcon
                style="margin-bottom: 1.5em; margin-top: 0.5em" [nzMessage]="'In order to ensure your placement is set up correctly, please contact your account manager'">
      </nz-alert>

      <form nz-form nzLayout="vertical" *ngIf="placementForm" [formGroup]="placementForm" (keydown.enter)="$event.preventDefault()">
        <div nz-row nzJustify="space-between" nzAlign="middle">
          <div class="header margin-bottom-small">
            <ng-container *ngIf="portalType !== 'portal'">{{editMode | titlecase}} Placement</ng-container>
            <span *ngIf="editMode !== 'CREATE' && !isPlacementLoading">
                '{{ placementForm.get('placementName').value }}'
              </span>
          </div>
          <div>
            <label *ngIf="currentPlacement?.placementId" class="placement-id">
              Placement ID: {{currentPlacement?.placementId}}
            </label>
            <label class="margin-right-small">Placement Status</label>
            <nz-switch id="placement-status" formControlName="status"
                       nzCheckedChildren="Active" nzUnCheckedChildren="Inactive">
            </nz-switch>
          </div>
        </div>
        <div class="form-section">
          <div nz-row nzJustify="space-between">
            <div class="section-title">
              <fa-icon icon="info" class="fa-lg"></fa-icon>
              <span class="padding-left-small">Details</span>
            </div>
          </div>
          <nz-row nzGutter="16">
            <nz-col nzLg="8" nzMd="8" nzSm="24">
              <nz-form-item>
                <nz-form-label nzRequired>Placement Name</nz-form-label>
                <nz-form-control nzErrorTip="Placement name is required">
                  <input nz-input placeholder="" formControlName="placementName" id="placement_name">
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzLg="8" nzMd="8" nzSm="24">
              <nz-form-item>
                <nz-form-label>Categories</nz-form-label>
                <nz-form-control>
                  <nz-tree-select [nzNodes]="iabCategories"
                                  [nzMaxTagCount]="4"
                                  style="width: 100%"
                                  nzShowSearch
                                  nzCheckable
                                  nzPlaceHolder="Select IAB categories that apply for this placement"
                                  formControlName="cat"
                                  [nzDropdownStyle]="{ 'max-height': '300px' }">
                  </nz-tree-select>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzLg="8" nzMd="8" nzSm="24">
              <nz-form-item>
                <nz-form-label>Content Genre</nz-form-label>
                <nz-form-control>
                  <nz-select formControlName="defaultGenre" nzPlaceHolder="Select content genres"
                             style="width: 100%;"
                             id="content-genre" nzAllowClear
                             [nzSuffixIcon]="selectIcons['content-genre'] ? arrowUp : arrowDown"
                             (nzOpenChange)="onChangeSelectIconState($event, 'content-genre')">
                    <nz-option *ngFor="let option of contentGenres$ | async"
                               [nzLabel]="option.value | titlecase"
                               [nzValue]="option.value">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
          <nz-row nzGutter="16">
            <nz-col *ngIf="portalType == 'supply'" nzLg="8" nzMd="8" nzSm="24">
              <nz-form-item>
                <nz-form-label>Demand Profile</nz-form-label>
                <nz-form-control>
                  <nz-select (nzOpenChange)="onChangeSelectIconState($event, 'demand-profile')" [nzSuffixIcon]="selectIcons['demand-profile'] ? arrowUp : arrowDown"
                             formControlName="demandProfile"
                             id="demand-profile"
                             nzPlaceHolder="Select demand profile"
                             nzShowArrow="false"
                             nz-tooltip
                             nzTooltipTitle="Traffic source setting: {{ptsDemandProfile}}"
                             style="width: 100%;">
                    <nz-option *ngFor="let option of demandProfiles"
                               [nzLabel]="option.displayName"
                               [nzValue]="option.value">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzLg="8" nzMd="8" nzSm="24">
              <nz-form-item>
                <nz-form-label>External Source ID
                  <span *ngIf="externalSourceIdTooltip"
                        [nzTooltipTitle]="externalSourceIdTooltip"
                        nz-tooltip>
                      <fa-icon class="fa-md font-icon-color-grey-cool font-icon-hover-color-primary-main padding-left-smallest"
                               icon="question-circle"></fa-icon>
                  </span>
                </nz-form-label>
                <nz-form-control>
                  <input formControlName="externalSourceId" id="portal_source_id" nz-input placeholder="">
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col nzFlex="auto">
              <nz-form-item>
                <nz-form-label>Notes</nz-form-label>
                <nz-form-control>
                  <input autocomplete="off" formControlName="notes" id="notes"
                         nz-input
                         placeholder="Enter a description">
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
          <nz-row nzGutter="16">
            <nz-col nzLg="8" nzMd="8" nzSm="24">
              <nz-form-item>
                <nz-form-label nzRequired>Media Type</nz-form-label>
                <nz-form-control nzErrorTip="Select media type">
                  <nz-select (nzOpenChange)="onChangeSelectIconState($event, 'media_type')"
                             [nzSuffixIcon]="selectIcons['media_type'] ? arrowUp : arrowDown"
                             formControlName="mediaType"
                             id="placement-media-type"
                             nzPlaceHolder="Select media type"
                             nzShowArrow="false" style="width: 100%;">
                    <nz-option nzLabel="Site" nzValue="SITE"></nz-option>
                    <nz-option nzLabel="App" nzValue="APP"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col *ngIf="fc.mediaType.value === 'APP'" nzLg="8" nzMd="8" nzSm="24">
              <nz-form-item>
                <nz-form-label [nzRequired]="isRequiredFieldByPubTs">App Name</nz-form-label>
                <nz-form-control nzErrorTip="Required field">
                  <input nz-input placeholder="" formControlName="defaultAppName" id="default_app_name">
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col *ngIf="fc.mediaType.value === 'APP'" nzLg="8" nzMd="8" nzSm="24">
              <nz-form-item>
                <nz-form-label [nzRequired]="isRequiredFieldByPubTs">App URL</nz-form-label>
                <nz-form-control nzErrorTip="Required field">
                  <input nz-input placeholder="" formControlName="defaultAppStoreUrl" id="default_app_store_url">
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col *ngIf="fc.mediaType.value" nzLg="8" nzMd="8" nzSm="24">
              <nz-form-item>
                <nz-form-label [nzRequired]="isRequiredFieldByPubTs && fc.mediaType.value !== 'SITE'">
                  {{fc.mediaType.value == 'SITE' ? 'Domain' : 'App Bundle'}}
                </nz-form-label>
                <nz-form-control nzErrorTip="Required field">
                  <input nz-input placeholder="" formControlName="defaultDomainBundle" id="default_domain_bundle">
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>
          <nz-row nzGutter="16">
            <nz-col nzLg="24" nzMd="24" nzSm="24">
              <div class="display-flex flex-wrap chb-flex-gap">
                <nz-form-item>
                  <nz-form-control>
                    <label formControlName="vpaidSupport" id="vpaid_support" nz-checkbox>VPAID Support</label>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-control>
                    <label formControlName="coppa" id="coppa" nz-checkbox>
                      COPPA Enforcement
                      <span *ngIf="externalSourceIdTooltip" nz-tooltip nzTooltipTitle="Enforcing COPPA will heavily limit traffic for this supply">
                        <fa-icon class="fa-md font-icon-color-grey-cool font-icon-hover-color-primary-main padding-left-smallest" icon="question-circle"></fa-icon>
                      </span>
                    </label>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-control>
                    <label formControlName="lda" id="lda" nz-checkbox>LDA Support</label>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngIf="portalType == 'portal'">
                  <nz-form-control>
                    <label formControlName="demandProfile" id="placement-external-demand-self-pmp" nz-checkbox>
                      Exclude Nexxen demand from this placement (only Publisher PMPs will be eligible to bid)
                    </label>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </nz-col>
          </nz-row>
        </div>

        <div class="form-section" id="placement-content-controls-section-form">
          <div class="section-title">
            <fa-icon class="fa-lg" icon="cogs"></fa-icon>
            <span class="padding-left-small">Content Controls</span>
          </div>
          <nz-alert [nzMessage]="contentControlsAlert" nzShowIcon nzType="info" style="margin-bottom: 1.5em; margin-top: 0.5em">
            <ng-template #contentControlsAlert>
              <ng-container *ngIf="portalType === 'supply'">
                Content controls section is for overriding parent traffic source values
              </ng-container>
              <ng-container *ngIf="portalType === 'portal'">
                Content controls section is for overriding the integration details settings
              </ng-container>
            </ng-template>
          </nz-alert>

          <app-content-controls [parentFormGroup]="placementForm">
          </app-content-controls>
        </div>

        <div *ngIf="portalType === 'supply' || (portalType === 'portal' && isShowFloors)" class="form-section" id="placement-floors-section">
          <div class="section-title">
            <fa-icon class="fa-lg" icon="dollar-sign"></fa-icon>
            <span class="padding-left-small">Floors {{portalType !== 'portal' ? '& Commissions' : ''}}</span>
          </div>

          <nz-alert nzType="info" nzShowIcon style="margin-bottom: 1.5em; margin-top: 0.5em" [nzMessage]="floorsAlert">
            <ng-template #floorsAlert>
              <ng-container *ngIf="portalType === 'supply'">
                Floors & commissions section is for overriding parent traffic source values
              </ng-container>
              <ng-container *ngIf="portalType === 'portal'">
                This floors section is for overriding the integration details settings
              </ng-container>
            </ng-template>
          </nz-alert>

          <nz-row>
            <nz-col nzMd="16" nzSm="24" *ngIf="portalType === 'supply'" id="placement-commission-section">
              <app-placement-commissions *ngIf="isReadyToLoadChild('commissions')"
                                        [editMode]="editMode"
                                        [placementCommission]="currentPlacement && currentPlacement.commissions && currentPlacement.commissions[0] ?
                                                                currentPlacement.commissions[0] : null"
                                        [placementId]="currentPlacementId"
                                        [pubTsCommission]="pubTsCommission"
                                        (updatedCommission)="onCommissionsUpdate($event)">
              </app-placement-commissions>
            </nz-col>

            <nz-col nzMd="4" nzSm="24" *ngIf="portalType === 'supply'" style="text-align: center">
              <nz-form-item>
                <nz-form-label></nz-form-label>
                <nz-form-control>
                  <label
                    nz-checkbox
                    formControlName="bidfloorOpti"
                    id="placement-bidfloor-optimization">
                    Bid floor adjustment optimization
                  </label>
                </nz-form-control>
              </nz-form-item>
            </nz-col>

            <nz-col nzMd="4" nzSm="24" *ngIf="portalType === 'supply'" style="text-align: center">
              <nz-form-item>
                <nz-form-label></nz-form-label>
                <nz-form-control>
                  <label
                    nz-checkbox
                    formControlName="commissionOpti"
                    id="placement-commission-optimization">
                    Commission optimization
                  </label>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>

          <app-placement-floors *ngIf="isReadyToLoadChild('floors')"
                                [portalType]="portalType"
                                [placementMode]="editMode"
                                [commissionsChange]="commissionsToSubmit"
                                [commissionsStart]="currentPlacement?.commissions?.[0]"
                                [pubTsCommission]="pubTsCommission"
                                [pubTsCot]="pubTsCot"
                                [placementCot]="currentPlacement?.cot"
                                [placementFloors]="currentPlacement?.floors"
                                [placementId]="currentPlacementId"
                                [countries]="countries"
                                [pubTsFloors]="pubTsFloors"
                                (updatedFloors)="onFloorsUpdate($event)">
          </app-placement-floors>
        </div>

        <div class="form-section">
          <div class="section-title">
            <fa-icon icon="ban" class="fa-lg"></fa-icon>
            <span class="padding-left-small">Blocked Lists</span>
          </div>

          <app-blocked-lists *ngIf="isReadyToLoadChild('blocked-lists')"
                             [portalType]="portalType"
                             [blockedListsData]="blockedListsData"
                             [iabCategories]="iabCategories"
                             [iabBannerAdTypes]="iabBannerAdTypes$ | async"
                             [iabCreativeAttributes]="iabCreativeAttributes$ | async"
                             [dspAccounts]="dspAccounts$ | async"
                             (updatedBlockedLists)="onBlockedListsUpdate($event)">
          </app-blocked-lists>
        </div>

        <div class="form-section" id="placement-blocked-advertisers-section">
          <div class="section-title">
            <fa-icon class="fa-lg" icon="ban"></fa-icon>
            <span class="padding-left-small">Blocked Advertisers</span>
          </div>

          <app-blocked-advertisers
            [badvDataSize]="badvDataSize"
            [badvData]="badvData"
            [placementId]="currentPlacement?.placementId"
            (updatedBadv)="onBadvUpdate($event)"
            [editMode]="editMode"
          >
          </app-blocked-advertisers>
        </div>

        <div class="form-section" *ngIf="portalType === 'supply'" id="placement-domain-bundles-section">
          <div class="section-title">
            <fa-icon icon="list" class="fa-lg"></fa-icon>
            <span class="padding-left-small">Site/App Bundles</span>
          </div>

          <app-domain-bundles *ngIf="isReadyToLoadChild('domain-bundles')"
                              [editMode]="editMode"
                              [parentType]="'PLACEMENT'"
                              [parent]="currentPlacement"
                              [pubTsId]="pubTsId"
                              (updatedDomainBundleFilter)="onFilterDomainBundleUpdate($event)"
                              (domainBundlePayloadUpdated)="onDomainBundlePayloadUpdated($event)"
                              (domainBundleFileUpdated)="onDomainBundleFileUpdated($event)"
                              (domainBundleFileRemoved)="onDomainBundleFileRemoved()"
          >
        </app-domain-bundles>
      </div>

      </form>

      <div class="footer">
        <a (click)="onBackToTableClick()" class="link-button margin-right-1 align-self-center font-icon-color-grey font-icon-hover-color-mid-dark" id="placement-cancel">Cancel</a>
        <button type="button" nz-button nzSize="large" (click)="onSubmitPlacement()"
                [nzLoading]="editMode !== 'CREATE' && isPlacementLoading"
                nzType="primary" id="placement-submit" class="primary-button">Submit
        </button>
      </div>

    </nz-card>
  </div>
</nz-spin>

<ng-template #arrowUp>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-open"></fa-icon>
</ng-template>
<ng-template #arrowDown>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-close"></fa-icon>
</ng-template>

<app-modal [isModalVisible]="isModalVisible"
           [modalButtons]="[{type: 'CANCEL', text: 'Cancel'}, {type: 'APPROVE',text: 'Yes, save'}]"
           (modalEvent)="onSubmitPlacement($event)">
  <app-commission-fixed-cpm-warning-text
    [commissionsValueWithWarning]="this.commissionsToSubmit?.placementCommissions?.[0]?.commission"
  ></app-commission-fixed-cpm-warning-text>
</app-modal>
